.App {
  text-align: center;
}
.App {
  text-align: center;
}

/*---------------------------------------------
    Header / layouts
--------------------------------------------- */
.header-part {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 99;
  background-color: rgba(255, 255, 255);
  top: 0;
}
.head-icon {
  width: 180px;
  height: 50px;
  margin: 18px 0 0 20px;
  background-image: url("./images/head_LOGO.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.head-txt {
  margin-top: -65px;
  color: #333333;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 27px;
  font-weight: bold;
}
.head-txt ul {
  text-align: left;
  margin-left: 180px;
}
.head-txt ul li {
  list-style: none;
}
.head-tel {
  padding-left: 8px;
  font-size: 22px;
  color: #12af07;
  /* z-index: 9999; */
}
.head-menu-mx {
  position: fixed;
  top: 3px;
  right: 20px;
  display: block;
}
.head-menu-ul {
  display: flex;
}
.head-menu-ul a {
  text-decoration: none;
}
.head-menu-ul li {
  list-style: none;
  margin-left: 8px;

  /* box-shadow: 1px 1px 4px rgb(168, 168, 168); */
  cursor: pointer;
  transition: 0.5s;
}
.head-menu-plan {
  background-color: #fff;
  font-weight: bold;
  font-size: 18px;
  color: #f87128;
  border: 4px solid #f87128;
  padding: 3px 8px;
}
.head-menu-form {
  background-color: #f87128;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 7px 8px;
}
.head-menu-ul li:hover {
  opacity: 0.4;
}
.tel-a a {
  text-decoration: none;
}
.menu-bar {
  display: none;
}
@media screen and (max-width: 960px) {
  .head-menu-mx {
    display: none;
  }
  .head-menu-ul li {
    margin-left: 20px;
  }
  .menu-bar {
    display: block;
  }
}
@media (min-width: 560px) {
  a[href^="tel:"] {
    pointer-events: none;
    cursor: default;
  }
}
@media (max-width: 560px) {
  .head-txt ul {
    margin-left: 166px;
    margin-top: 10px;
  }
  .menu-bar {
    display: block;
  }
}

@media screen and (max-width: 430px) {
  .header-part {
    height: 60px;
  }
  .sp-none {
    display: none;
  }
  .head-menu-form {
    display: none;
  }
  .head-tel {
    font-size: 20px;
  }
  .head-txt {
    margin-top: -60px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 22px;
  }
  .head-txt ul {
    margin-left: 12px;
    margin-top: 24px;
  }
  .head-menu-plan {
    font-size: 14px;
    color: #f87128;
    border: 3px solid #f87128;
    padding: 4px 8px;
    margin-top: -4px;
  }
  .head-icon {
    width: 45px;
    height: 35px;
    margin: 12px 0 0 10px;
    background-image: url("./images/icon-2x.png");
  }
  .head-menu {
    top: 0;
  }
}
/*---------------------------------------------
    Header / layouts   -- END --
--------------------------------------------- */

/*---------------------------------------------
    Menu / layouts 
--------------------------------------------- */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 20px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ff8f0f;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ff8f0f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #737373;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 50%;
}

/* General sidebar styles */
.bm-menu {
  background: #fea517;
  padding: 40px 0;
  font-size: 24px;
  height: 50%;
  width: 100%;
  text-align: left;
  line-height: 60px;
  z-index: 999;
  font-weight: bold;
  color: rgb(243, 243, 243);
}
.bm-menu a {
  text-decoration: none;
  color: rgb(243, 243, 243);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fea517;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  outline: none;
  border-bottom: 1px solid #fff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 560px) {
  .bm-burger-button {
    display: block;
  }
}
@media screen and (max-width: 430px) {
  .bm-burger-button {
    display: block;
  }
}
/*---------------------------------------------
    Menu / layouts  ---END---
--------------------------------------------- */

.thank-txt {
  background-image: url("./images/thanks-txt.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 700px;
  height: 50px;
  margin: 0 auto;
  padding-top: 500px;
}
.poru-illu {
  background-image: url("./images/poru-illus.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 300px;
  height: 200px;
  margin: -170px auto 0;
}
.thanks-bg {
  background-color: #ffecbd;
  height: 780px;
}
.top-link {
  color: #fff;
  width: 200px;
  height: 50px;
  margin: 30px auto;
  background-color: #f87128;
  line-height: 50px;
  font-weight: bold;
  font-size: 24px;
}
.top-link a {
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 960px) {
  .mitumori-part {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .form-title {
    background-image: url("./images/Form-titleIllus.png");
    width: 300px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 10px auto 0;
  }
  .contact-part {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .form-label {
    padding-bottom: 12px;
  }
  .input-name {
    width: 85%;
    height: 30px;
    border: none;
    border-bottom: 3px solid rgb(231, 103, 5);
    padding-left: 8px;
    font-size: 14px;
    margin: 0 auto;
  }
  .thank-txt {
    background-position: center;
    width: 330px;
    height: 50px;
    margin: 0 auto;
    padding-top: 300px;
  }
  .poru-illu {
    width: 220px;
    height: 220px;
    margin: -100px auto 0;
  }
  .top-link {
    color: #fff;
    width: 160px;
    height: 40px;
    margin: 10px auto;
    background-color: #f87128;
    line-height: 40px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .home-btn {
    width: 60px;
    height: 60px;
    font-size: 12px;
    line-height: 60px;
  }
}
